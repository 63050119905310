import {
  ref, onMounted, getCurrentInstance, computed
} from 'vue'
import { reportingUseCase } from '@/domain/usecase'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default {
  setup() {
    const app = getCurrentInstance()
    const store = useStore()
    const { $toast } = app!.appContext.config.globalProperties
    const data = ref([])
    const route = useRoute()
    const router = useRouter()
    const inputSearch = ref('')
    const iconPath = ref('')
    const typeMenu = ref('')
    const dataFilter = computed(() => data.value.filter((item: any) => {
      if (typeMenu.value === '' || typeMenu.value === 'all') {
        return item.Judul.toLowerCase().includes(inputSearch.value.toLowerCase())
      }
      return item.Judul.toLowerCase().includes(inputSearch.value.toLowerCase()) && (item.Tipe.toLowerCase() === typeMenu.value)
    }))
    const getAllData = async () => {
      store.dispatch('showLoading')
      ///
      const response = await reportingUseCase.getAllDynamicReport()
      console.log('response', response)
      if (response.error) {
        $toast.add({
          severity: 'error', detail: response.message, group: 'bc', life: 3000
        })
      } else {
        data.value = await response.result
      }
      store.dispatch('hideLoading')
    }

    const changeSearch = (val: any) => {
      console.log('search val', val)
    }

    const showDetailReportSetting = (val: any) => {
      if (store.getters['appActiveUser/getAgent']?.id) {
        router.push({ name: 'report-setting-data-detail', params: { id: val.Id }, query: { title: val.Judul, keterangan: val.Keterangan } })
      }
    }
    onMounted(() => {
      getAllData()
    })
    return {
      data,
      inputSearch,
      iconPath,
      changeSearch,
      showDetailReportSetting,
      dataFilter,
      typeMenu
    }
  }
}
